// Root Reducer

import { combineReducers } from 'redux';
// import authUserReducer from './authUser';
import streetReducer from './streetReducer';
import crowdFundReducer from './crowdFundReducer'
export let rootReducer = combineReducers({
	// authUser: authUserReducer,
	street: streetReducer,
	crowdFund :crowdFundReducer,
});

export default rootReducer;
