import { RepositoryFactory } from "../../repository/RepositoryFactory";
var LeadRepo = RepositoryFactory.get("newLead");

export const createNewLead =
  (data, onSuccess = () => {}) =>
  async (dispatch) => {
    await dispatch(createLoader(true));
    let { bootCheck, ...payload } = data;
    if (bootCheck) {
      dispatch({
        type: "STREET_CREATE_RESPONSE",
        payload: "data",
      });
      onSuccess();
    } else {
      try {
        let { data } = await LeadRepo.createNewLead(payload);
        if (data) {
          dispatch({
            type: "STREET_CREATE_RESPONSE",
            payload: data,
          });
          onSuccess();
          await dispatch(createLoader(false));
        } else {
          await dispatch(createLoader(false));
        }
      } catch (e) {
        console.log(e.response.data.error);
        if (e.response.data.error == "Conflict - duplicate") {
          dispatch({
            type: "STREET_CREATE_RESPONSE",
            payload: "data",
          });
          onSuccess();
        } else {
          let error = e.response.data.error.match(/'([^']+)'/)[0].slice(1, -1);
          dispatch({
            type: "STREET_ERROR_RESPONSE",
            payload: { [error]: true },
          });
        }
        await dispatch(createLoader(false));
      }
    }
  };
export const setStreetError = () => async (dispatch) => {
  dispatch({
    type: "STREET_ERROR_RESPONSE",
    payload: null,
  });
};
export const createLoader = (val) => async (dispatch) => {
  dispatch({
    type: "CREATE_LOADER",
    payload: val,
  });
};
