import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import Header from '../components/Header/Header';
import Topbar from '../components/TopBar/Topbar';
import KopieSection from '../components/KopieSection/KopieSection';
import AnnpakSection from '../components/AnnpakSection';
import Footer from '../components/Footer';

const AdminView = props => {
	return (
		<>
			<Topbar />
			<Header />
			<KopieSection />
			<AnnpakSection />
			<Footer />
		</>
	);
};

export default AdminView;
