import AuthView from "../views/auth/AuthView";
import Kleinstrand from "../views/Kleinstrand";
import MainView from "../views/MainView";
import Uitgelicht from "../views/Uitgelicht";

let routes = [
  {
    path: "/auth",
    component: AuthView,
    layout: "auth",
  },
  {
    path: "/",
    component: MainView,
    layout: "main",
  },
  {
    path: "/uitgelicht",
    component: Uitgelicht,
    layout: "main",
  },
  {
    path: "/holidaysuiteskleinstrand",
    component: Kleinstrand,
    layout: "main",
  },
];
export default routes;
