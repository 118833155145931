import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Person from '../assets/img/Person.png';
import RightArrow from '../assets/img/rightArrow.png';

function KopieSection() {
	return (
		<Container className='kopieSection' fluid>
			<Container className='pt-5 p-0 p-md-auto'>
				<Row className='mt-md-5'>
					<div className='text-center kopieSection_text d-block d-md-none'>
						<h1 className='my-4 font-family-bold'>
							SAMEN ZORGELOOGS
						</h1>
						<h1 className='my-4 font-family-bold'>
							OP WEG NAAR VASTGOED
						</h1>
						<span className='font-family-light fs--30 text-footer-color'>
							Wij ontzorgen tijdens het gehele traject
						</span>
					</div>
					<Col
						xs='6'
						md='6'
						className='p-2 p-md-auto align-self-center'
						//  className='h-100 align-self-center'
					>
						<div className='text-left kopieSection_text d-none d-md-block'>
							<h1 className='my-4 font-family-bold'>
								SAMEN ZORGELOOGS
							</h1>
							<h1 className='my-4 font-family-bold'>
								OP WEG NAAR VASTGOED
							</h1>
							<span className='font-family-light fs--30 text-footer-color'>
								Wij ontzorgen tijdens het gehele traject
							</span>
						</div>
						<p className='my-md-4 font-family-light'>
							Vastgoed kopen doe je niet elke dag. Het is
							belangrijk dat je er goed over nadenkt en weet wat
							je doet. Daarom kiest Yntro voor een overzichtelijke
							aanpak waarin we je zorgvuldig begeleiden bij elke
							stap van het traject.
						</p>
						{/*  */}
						<p className='my-md-4 font-family-light'>
							We willen enerzijds jouw wensen scherpstellen en
							anderzijds haarfijn uitleggen wat je al dan niet mag
							verwachten. Zo zorgen we er samen voor dat je de
							trotse eigenaar wordt van vastgoed.
						</p>

						<div className='d-flex mb-2'>
							<button
								type='button'
								className='text-form-color btn-md btn-yellow py-2 fs--18 px-md-5 text-left btn-hover border-0 kopieSection_text_button'
								onClick={() => window.scrollTo(0, 180)}
							>
								{/* Maak vrijblijvend enn afspraak */}
								Maak vrijblijvend een afspraak
								<span>
									<img
										src={RightArrow}
										alt='symbol'
										className='img-fluid'
									/>
								</span>
							</button>
						</div>
					</Col>
					<Col
						xs='6'
						md='6'
						className='p-0 p-md-auto'
						// className='text-center align-self-center  h-md-100 p-0 p-md-auto'
						// h-100
					>
						{/* <div className='d-flex flex-wrap'> */}
						<img
							src={Person}
							alt='person'
							className='h-100 w-100 p-0 p-md-auto person-img'
							// className='img-fluid'
							// style={{
							// 	height: '356px !important',
							// }}
						/>
						{/* img-fluid */}
						{/* </div> */}
					</Col>
				</Row>
			</Container>
		</Container>
	);
}

export default KopieSection;
