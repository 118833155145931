import StreetRepository from './StreetRepository';
import CrowdfundRepository from './CrowdfundRepository'
const repositories = {
	newLead: StreetRepository,
	newCrowdFund: CrowdfundRepository,
};
// eslint-disable-next-line import/prefer-default-export
export const RepositoryFactory = {
	get: name => repositories[name],
};
