// import TopBar from "../components/Header/Topbar";
// import Header from '../components/Header/Header';
// import Footer from "../components/Footer/Footer";
import { Container } from 'reactstrap';

const Main = props => {
	return (
		<>
			{/* <Header /> */}
			{/* <HeroSection /> */}
			<div>{props.children}</div>
			{/* <Footer /> */}
		</>
	);
};

export default Main;
