import React, { useEffect } from "react";
import { useState } from "react";
import {
  Col,
  Row,
  Form,
  Label,
  Input,
  Modal,
  ModalBody,
  Button,
  Spinner,
  FormFeedback,
} from "reactstrap";
import {
  createCrowdfund,
  setCrowdFundError,
} from "../../store/actions/CrowdfundAction";
import rightArrow from "../../assets/img/Arrow-Sign.png";

import { useDispatch, useSelector } from "react-redux";
import ThankYouForm from "./ThankyouForm";
import { useHistory, useParams } from "react-router-dom";
function Register() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [siteSubId, setSiteSubId] = useState("");
  const [publisherId, setPublisherId] = useState("");
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.crowdFund);
  let params = useParams();
  const history = useHistory();

  useEffect(() => {
    const query = history.location.search;
    if (query) {
      const site_subid = new URLSearchParams(query).get("oa_id");
      const publisher_id = new URLSearchParams(query).get("siteid");
      // console.log(site_subid, 'site_subid');
      setSiteSubId(site_subid);
      // console.log(publisher_id, ' publisher_id');
      setPublisherId(publisher_id);
    }
  }, []);
  const checkNameRegex = (val) => {
    let validName = /^[a-zA-Z\s.,'-]{1,30}$/;
    // let validName = /^[a-zA-Z]+ [a-zA-Z]+$/;
    if (val != "") {
      if (validName.test(val)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  const checkLastName = (val) => {
    let validName = /^[a-zA-Z\s.,'-]{1,30}$/;
    if (val != "") {
      if (validName.test(val)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const checkEmailRegex = (val) => {
    let validName = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z]{2,}$/;
    if (val != "") {
      if (validName.test(val)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  const checkPhoneRegex = (val) => {
    let validName = /^(0|\+?32|00\s?32)([-\s]?\d[-\s]?){7,8}\d$/;
    if (val != "") {
      if (validName.test(val)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      checkNameRegex(firstName) === false &&
      checkLastName(lastName) === false &&
      checkEmailRegex(email) === false &&
      checkPhoneRegex(phoneNumber) === false
    ) {
      let phoneNumberWithoutPunctuation = phoneNumber
        .replace(/[\s-]/g, "")
        .replace(/^00(\d+)$/, "$1");

      if (!phoneNumberWithoutPunctuation.startsWith("0")) {
        // Prepend a plus sign
        if (!phoneNumberWithoutPunctuation.startsWith("+")) {
          phoneNumberWithoutPunctuation = "+" + phoneNumberWithoutPunctuation;
        }
      }
      console.log(
        "phoneNumberWithoutPunctuation: ",
        phoneNumberWithoutPunctuation
      );
      let data = {
        firstname: firstName,
        lastname: lastName,
        email: email,
        phone_number: phoneNumberWithoutPunctuation,
        language: "nl_BE",
        answers: ["3631"],
        site_custom_url: "http://yntro.aanvraaginformatie.be/",
        site_custom_name: "yntro_ibisdehaan",
        site_subid: siteSubId,
        publisher_id: publisherId,
      };
      dispatch(createCrowdfund(data));
    }
  };

  const { create_response, error_response } = useSelector(
    (state) => state.crowdFund
  );

  console.log(create_response, "create_response");

  return (
    <div className="headerSection__formDiv">
      <h3 className="text-white text-center mt-4 ">
        <span className="fw--300">Ja, ik wil </span> vrijblijvend meer
        informatie over dit project.{" "}
      </h3>
      {create_response !== null ? (
        <>
          <ThankYouForm />
        </>
      ) : (
        <>
          <Form className=" pt-2 px-3 pb-3 form-height" onSubmit={handleSubmit}>
            <Row className="mt-sm-2" noGutters>
              <Col xs="12" sm={10} className="m-auto">
                <label className=" text-white fs--18 fw--500 mb-0 font-family-OpenSan">
                  Voornaam
                </label>
                <Input
                  required
                  className="form-Input pr-0 m-0  border-0 rounded-0"
                  placeholder="bv. Peter"
                  invalid={
                    checkNameRegex(firstName) || error_response?.firstname
                  }
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    error_response?.firstname && dispatch(setCrowdFundError());
                  }}
                />
                <FormFeedback>Voornaam niet correct</FormFeedback>
              </Col>
            </Row>
            <Row className="mt-sm-2" noGutters>
              <Col xs="12" sm={10} className="m-auto">
                <span>
                  <label className="text-white fs--18 fw--500  mb-0 font-family-OpenSan">
                    Achternaam
                  </label>
                  <Input
                    required
                    className="  form-Input pr-0 m-0  border-0 rounded-0"
                    placeholder="bv. Jansen"
                    invalid={
                      checkLastName(lastName) || error_response?.lastname
                    }
                    onChange={(e) => {
                      setLastName(e.target.value);
                      checkNameRegex(firstName);
                      error_response?.lastname && dispatch(setCrowdFundError());
                    }}
                  />
                  <FormFeedback>Achternaam niet correct</FormFeedback>
                </span>
              </Col>
            </Row>
            <Row className="mt-sm-2" noGutters>
              <Col xs="12" sm={10} className="m-auto">
                <label className=" text-white fs--18 fw--500 mb-0 font-family-OpenSan">
                  E-mail adres
                </label>
                <Input
                  required
                  className="  form-Input pr-0 mr-0  border-0 rounded-0"
                  placeholder="bv. peter@jansen.be"
                  invalid={checkEmailRegex(email) || error_response?.email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    error_response?.email && dispatch(setCrowdFundError());
                  }}
                />
                <FormFeedback>E-mailadres niet correct</FormFeedback>
              </Col>
            </Row>
            <Row className="mt-sm-2" noGutters>
              <Col xs="12" sm={10} className="m-auto">
                <label className=" text-white fs--18 fw--500 mb-0 font-family-OpenSan">
                  Telefoonnummer
                </label>
                <Input
                  required
                  className="form-Input pr-0 m-0 border-0 rounded-0"
                  placeholder="bv. 0478504030"
                  invalid={
                    checkPhoneRegex(phoneNumber) || error_response?.phone_number
                  }
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                    error_response?.phone_number &&
                      dispatch(setCrowdFundError());
                  }}
                />
                <FormFeedback>Telefoonnummer niet correct</FormFeedback>
              </Col>
            </Row>

            <button
              className="fw--700 fs--21 font-family-MonSerrat text-dark mt-4 botton_vragaan "
              type="submit"
            >
              {loading ? (
                <Spinner size="md" color="dark" />
              ) : (
                <>
                  Vraag aan
                  <span className="ml-2">
                    {" "}
                    <img
                      src={rightArrow}
                      alt="img"
                      className="img-fluid"
                    />{" "}
                  </span>
                </>
              )}
            </button>
            <p className="text-white my-3 px-3 fs--10  text-center w-100 ">
              Door hieronder op 'ik ben geïnteresseerd' te klikken, geef je
              toestemming aan Yntro om de hierboven ingediende persoonlijke
              informatie op te slaan en te verwerken om je van de gevraagde
              inhoud te voorzien.
            </p>
          </Form>
        </>
      )}
    </div>
  );
}

export default Register;
