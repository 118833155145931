import React, { useEffect } from "react";
import AnnPakSection from "../components2/AnnPakSection";
import Footer from "../components2/Footer";
import Header from "../components2/Header/Header";
import ImagesSection from "../components2/ImagesSection";
import KopieSection from "../components2/KopieSection";

function Kleinstrand() {
  useEffect(() => {
    document.title = `Yntro`;
  });
  return (
    <>
      <Header />
      <ImagesSection />
      <KopieSection />
      <AnnPakSection />
      <Footer />
    </>
  );
}

export default Kleinstrand;
