import React from 'react';
import { Container } from 'reactstrap';
import Logo from '../../assets/img/Navbar-logo.png';

function Topbar() {
	return (
		<>
			<div className='bg-navbar-color'>
				<Container>
					{/* <h3 className='text-yellow'>yntro</h3> */}
					<img src={Logo} alt='logo' className='ml-4 py-2' />
				</Container>
			</div>
		</>
	);
}

export default Topbar;
