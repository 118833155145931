import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Laag88 from '../assets/img/header_beneath_1.jpg';
import Laag89 from '../assets/img/header_beneath_2.jpg';
import Laag90 from '../assets/img/header_beneath_3.jpg';
import Laag91 from '../assets/img/header_beneath_4.jpg';

function ImagesSection() {
	return (
		<Container fluid className='my-3'>
			<Row>
				<Col lg={3} md={6} sm={12} xs={12}>
					<img
						src={Laag88}
						style={{ width: '100%' }}
						alt=''
						className='py-3'
					/>
				</Col>
				<Col lg={3} md={6} sm={12} xs={12}>
					<img
						src={Laag89}
						alt=''
						style={{ width: '100%' }}
						className='py-3'
					/>
				</Col>
				<Col lg={3} md={6} sm={12} xs={12}>
					<img
						src={Laag90}
						alt=''
						style={{ width: '100%' }}
						className='py-3'
					/>
				</Col>
				<Col lg={3} md={6} sm={12} xs={12}>
					<img
						src={Laag91}
						alt=''
						style={{ width: '100%' }}
						className='py-3'
					/>
				</Col>
			</Row>
		</Container>
	);
}

export default ImagesSection;
