import React from 'react';
import { useState } from 'react';
import { Button, Container, Label, Modal, ModalBody, Row } from 'reactstrap';

function Footer() {
	const [clickable, setClickable] = useState(false);
	const [privacy, setPrivacy] = useState(false);

	const clickableToggle = () => {
		setClickable(!clickable);
	};
	const privacyToggle = () => {
		setPrivacy(!privacy);
	};
	return (
		<>
			<Modal isOpen={clickable} toggle={clickableToggle} size='lg'>
				<ModalBody>
					<Label className='custom-label'>
						<h2>Cookiebeleid</h2>
						<p className='my-2'>
							We onderscheiden functionele en niet-functionele
							cookies. Als je verder surft op onze website
							aanvaard je de functionele cookies. Deze zijn erop
							gericht om de websites goed te doen functioneren. We
							vragen jouw toestemming om niet-functionele cookies
							te gebruiken. Je kan jouw toestemming te allen tijde
							intrekken zonder dat deze intrekking een gevolg
							heeft op de rechtmatigheid van de verwerking
							voorafgaand aan de intrekking. Hiermee volgen wij en
							derden jouw surfgedrag op onze websites. Zo kunnen
							we zien wanneer je ons bezoekt, hoe lang je blijft,
							waarnaar je kijkt, wat je aanklikt, en ook via welke
							reclame je bij ons terecht kwam, ook zullen we
							soortgelijke technologische evoluties inzetten om
							nog beter te begrijpen wat jou interesseert. Dit
							doen we om onze e-mails persoonlijker te maken als
							we je reeds kennen, ook om jou beter te kunnen
							helpen aan het product dat jou interesseert, en om
							je onze publiciteit te tonen op andere websites van
							derden die jou kunnen aanspreken. Via jouw IP adres
							kunnen we je makkelijk herkennen als je in het
							verleden reeds met ons contact zocht.
						</p>
						<p className='my-2'>
							Naast eigen cookies, gebruiken wij cookies van
							bedrijven als Youtube, Google, Facebook, en andere
							affiliate partners. Sommige cookies dienen om
							partners te belonen voor hun inspanningen om jou tot
							bij ons te brengen, andere dienen om aan remarketing
							te kunnen doen: dit is je reclame laten zien op
							andere websites, terwijl je onze website reeds
							verliet.
						</p>
						<p>
							Je kan cookies altijd zelf verwijderen, in jouw
							browsers. Dit staat hier uitgelegd:
							<br />
							- Chrome
							<br />
							- Firefox
							<br />
							- Internet Explorer
							<br />
							- Edge
							<br />
							- Safari (iOS)
							<br />
							- Safari (macOS)
							<br />
							De cookies worden opgeslagen voor een periode van
							365 dagen.
							<br />
							De verzamelde data wordt verwerkt door onze
							marketingleveranciers.
						</p>
						<p className='my-2'>
							Je hebt allen tijde het recht om (a) toegang tot,
							verbetering of verwijdering van jouw
							persoonsgegevens te verzoeken, (b) de beperking van
							verwerking van jouw persoonsgegevens te verzoeken of
							zich te verzetten tegen de verwerking van jouw
							persoonsgegevens, (c) jouw persoonsgegevens in een
							gestructureerde, gangbare en machine-leesbare vorm
							te verkrijgen en (d) een klacht in te dienen bij de
							toezichthoudende overheid (in Belgi├½ de
							Gegevensbeschermingsautoriteit,
							www.gegevensbeschermingsautoriteit.be).
						</p>
						<p className='my-2'>
							Je kan deze rechten uitoefenen, door te mailen naar
							marketing@yntro.be.
						</p>
						<p>
							De verantwoordelijke is NV Bedecom met haar zetel te
							Gent, Kortrijksesteenweg 1043, ingeschreven in de
							KBO onder nummer BE0440 234 894. Je kan ons steeds
							bereiken op marketing@yntro.be
						</p>
					</Label>
				</ModalBody>
			</Modal>
			{/* Privacy */}
			<Modal isOpen={privacy} toggle={privacyToggle} size='lg'>
				<ModalBody>
					<Label className='custom-label'>
						<h2>Privacy beleid</h2>
						<h3>NIEUWBRIEVEN ΓÇô EMAILS</h3>
						<p>
							Als je van ons nieuwbrieven of mails ontvangt, is
							dit om je op de hoogte te brengen van de projecten
							waarvoor je informatie gevraagd hebt of voor
							soortgelijke producten. Je gaf ons hiervoor jouw
							toestemming door zelf aan te geven dat je graag de
							nieuwsbrief wou ontvangen en graag promoties
							ontvangt via email. Uiteraard kan je op gelijk welk
							moment uitschrijven, onderaan elke mail. Je hebt dus
							steeds het recht om jouw toestemming in te trekken.
							Dit kan door je uit te schrijven op de mail, of door
							ons te mailen op marketing@yntro.be. De intrekking
							van jouw toestemming heeft geen invloed op de
							rechtmatigheid van de verwerking voorafgaand aan de
							intrekking.
						</p>

						<p>
							Jouw naam, mailadres, taal en postcode zijn
							uiteraard onontbeerlijke persoonlijke gegevens om te
							verzamelen. In principe ontvangen we deze informatie
							van jou. Als je er echter voor koos om je te
							registreren via een social media account, ontvingen
							we deze gegevens via de Social Media. We raden je
							dan ook aan om de privacy policy van het door jou
							gebruikte Social Medium te lezen en aan te duiden
							welke gegevens men mag doorgeven aan derden zoals
							ons, en voor welk soort verwerking. Als je aan jouw
							Social Medium niet de toestemming gaf dat de
							ontvanger jouw gegevens mag doorgeven aan verbonden
							ondernemingen, dan zullen wij jouw toestemming
							hiervoor opnieuw vragen.
						</p>

						<p>
							Wij verzamelen ook informatie over wat je reeds
							kreeg van informatie, of je het opende, of je klikte
							op ├⌐├⌐n van de links, en nog andere metadata, en
							zullen dit in de toekomst ook uitbreiden met wat
							technisch mogelijk is. Deze data wordt bewaard bij
							verwerkers (mailsoftware providers). Wij kunnen jouw
							gegevens eveneens doorgeven aan derde partijen
							(inclusief overheden), indien wij hiertoe verplicht
							zijn op basis van wettelijke bepalingen of
							beslissingen van rechtbanken. Als je je inschrijft
							op onze nieuwsbrief, geven we jouw naam, mailadres
							en postcode en interesses ook door aan verbonden
							ondernemingen met soortgelijke producten zodat ook
							zij jou nuttige informatie kunnen bezorgen. Je hebt
							te allen tijde het recht om (a) toegang tot,
							verbetering of verwijdering van jouw
							persoonsgegevens te verzoeken, (b) de beperking van
							verwerking van jouw persoonsgegevens te verzoeken of
							zich te verzetten tegen de verwerking van jouw
							persoonsgegevens, (c) jouw persoonsgegevens in een
							gestructureerde, gangbare en machine-leesbare vorm
							te verkrijgen en (d) een klacht in te dienen bij de
							toezichthoudende overheid (in Belgi├½ de
							Gegevensbeschermingsautoriteit,
							https://www.gegevensbeschermingsautoriteit.be).
						</p>
						<p>
							Je kan deze rechten uitoefenen door te mailen naar
							marketing@yntro.be.
						</p>
						<p>
							Wij blijven je mailen tot je aangeeft, door
							uitschrijving, niets meer te willen ontvangen. Het
							idee om vastgoed aan te kopen als 2de verblijf of
							investering houdt vele mensen bezig, en kan vaak
							maar na 5 of 10 jaar resulteren tot een aankoop.
							Daarom bewaren we jouw gegevens tot 5 jaar na jouw
							laatste contact met onze firma.
						</p>
						<p>
							De verantwoordelijke is NV Bedecom met haar zetel te
							Gent, Kortrijksesteenweg 1043, ingeschreven in de
							KBO onder nummer BE0440 234 894. Je kan ons steeds
							bereiken op marketing@yntro.be.
						</p>
						<h3>COMMERCIELE GESPREKKEN</h3>
						<p>
							Door elkaar te ontmoeten wordt heel wat persoonlijke
							informatie gedeeld met elkaar, de informatie die
							relevant is om je verder te kunnen helpen, wordt
							vaak genoteerd door onze commerci├½le mensen in een
							CRM systeem. Dit zijn oa jouw naam, adres,
							contactgegevens, voorkeuren, budget, verwachtingen,
							inhoud van de vervolgafspraken... Wij kunnen jouw
							gegevens eveneens doorgeven aan derde partijen
							(inclusief overheden), indien wij hiertoe verplicht
							zijn op basis van wettelijke bepalingen of
							beslissingen van rechtbanken of wanneer dit
							noodzakelijk is in het kader van de overeenkomst.
						</p>
						<p>
							Deze informatie wordt gebruikt voor automatische
							besluitvorming en profiling. Dit helpt ons te
							bepalen naar welk event we jou het best uitnodigen,
							welke projecten we best voorstellen, welke adviseurs
							de beste match met jou zullen hebben, welke
							infothemaΓÇÖs jou het best interesseren, of we op
							korte termijn moeten zoeken naar een
							investeringstermijn of beter enkele maanden of jaren
							moeten wachten, wanneer we jou het best telefonisch
							bereikenΓÇª Je hebt uiteraard te allen tijde het
							recht om jou hiertegen te verzetten. Dit kan door
							dit eenvoudigweg te mailen naar marketing @yntro.be
							voordat het gesprek plaatsvindt. De reeds verwerkte
							informatie laten wissen, of jouw andere rechten
							uitoefenen (zie verder) kan met een eenvoudig
							mailtje, dat je kan sturen naar marketing@yntro.be.
						</p>
						<p>
							Deze informatie is steeds raadpleegbaar en wordt
							niet langer dan 5 jaar opgeslagen na elke actieve
							herbevestiging, omdat onze ervaring leert dat dit de
							periode is waarbinnen mensen daadwerkelijk overgaan
							tot hun eerste aankoop. Als je beslist om een optie
							te nemen, wordt ook jouw rekeningnummer bewaard voor
							3 jaar. Door deze policy te aanvaarden bij het
							doorgeven van jouw contactgegevens, via een opt
							ΓÇôin , geef je toestemming om de hierboven vermelde
							gegevens te verwerken. Je hebt ook steeds het recht
							om jouw toestemming in te trekken. Dit kan o.a. door
							ons te mailen op marketing@yntro.be. De intrekking
							van jouw toestemming heeft geen invloed op de
							rechtmatigheid van de verwerking voorafgaand aan de
							intrekking.
						</p>

						<p>
							Je hebt te allen tijde het recht om (a) toegang tot,
							verbetering of verwijdering van jouw
							persoonsgegevens te verzoeken, (b) de beperking van
							verwerking van jouw persoonsgegevens te verzoeken of
							zich te verzetten tegen de verwerking van je
							persoonsgegevens, (c) jouw persoonsgegevens in een
							gestructureerde, gangbare en machine-leesbare vorm
							te verkrijgen en (d) een klacht in te dienen bij de
							toezichthoudende overheid (in Belgi├½ de
							Gegevensbeschermingsautoriteit,
							https://www.gegevensbeschermin...).
						</p>
						<p>
							Je kan deze rechten uitoefenen, door te mailen naar
							marketing@yntro.be.
						</p>
						<p>
							De verantwoordelijke is NV Bedecom met haar zetel te
							Gent, Kortrijksesteenweg 1043, ingeschreven in de
							KBO onder nummer BE0440 234 894. Je kan ons steeds
							bereiken op marketing@yntro.be.
						</p>

						<h3>VASTGOED AANKOPEN</h3>

						<p>
							Als je beslist om klant te worden delen we heel wat
							informatie met elkaar. Veel persoonsgebonden
							informatie vindt men terug in de akte
							(identiteitsgegevens, nationaalnummer,
							huwelijksstelsel, rekeningnummersΓÇª. ). Het spreekt
							voor zich dat dit uiterst gevoelige informatie is en
							dat dit slechts voor een handvol mensen in onze
							organisatie toegankelijk is, zoverre we deze
							informatie nodig hebben om onze overeenkomst met
							elkaar goed te kunnen uitvoeren of om te voldoen aan
							wettelijke verplichtingen die op ons rusten. Indien
							je jouw gegevens niet met ons deelt kunnen wij de
							verkoop van het vastgoed niet afhandelen. Wij kunnen
							jouw gegevens eveneens doorgeven aan derde partijen
							(inclusief overheden), indien wij hiertoe verplicht
							zijn op basis van wettelijke bepalingen of
							beslissingen van rechtbanken of wanneer dit
							noodzakelijk is in het kader van de uitvoering van
							de overeenkomst.
						</p>

						<p>
							Je hebt te allen tijdehet recht om (a) toegang tot,
							verbetering of verwijdering van jouw
							persoonsgegevens te verzoeken, (b) de beperking van
							verwerking van jouw persoonsgegevens te verzoeken of
							zich te verzetten tegen de verwerking van je
							persoonsgegevens, (c) jouw persoonsgegevens in een
							gestructureerde, gangbare en machine-leesbare vorm
							te verkrijgen en (d) een klacht in te dienen bij de
							toezichthoudende overheid (in Belgi├½ de
							Gegevensbeschermingsautoriteit,
							https://www.gegevensbeschermingsautoriteit.be).
						</p>

						<p>
							Je kan deze rechten uitoefenen door te mailen naar
							marketing@yntro.be.
						</p>

						<p>
							De verantwoordelijke is NV Bedecom met haar zetel te
							Gent, Kortrijksesteenweg 1043, ingeschreven in de
							KBO onder nummer BE0440 234 894. Je kan ons steeds
							bereiken op marketing@yntro.be.
						</p>

						<h3>UITBATING</h3>

						<p>
							Als je bij ons vastgoed gekocht hebt ├⌐n een
							uitbatingsmandaat bent aangegaan voor de exploitatie
							ervan; dan verwerken wij persoonsgebonden informatie
							om deze overeenkomst zo goed mogelijk te kunnen
							uitvoeren. Dit gaat over jouw rekeningnummer voor de
							uitbetalingen, de noodzakelijke identiteitsgegevens
							en de correspondentie die we met jou hierover
							voeren. Deze data wordt bewaard bij onze CRM-partner
							en cloudproviders. Wij kunnen jouw gegevens eveneens
							doorgeven aan derde partijen (inclusief overheden),
							indien wij hiertoe verplicht zijn op basis van
							wettelijke bepalingen of beslissingen van
							rechtbanken of wanneer dit noodzakelijk is in het
							kader van de uitvoering van de overeenkomst. Wij
							kunnen de overeenkomst niet uitvoeren indien je jouw
							persoonsgegevens niet aan ons verstrekt.
						</p>

						<p>
							Je hebt allen tijde het recht om (a) toegang tot,
							verbetering of verwijdering van jouw
							persoonsgegevens te verzoeken, (b) de beperking van
							verwerking van jouw persoonsgegevens te verzoeken of
							zich te verzetten tegen de verwerking van je
							persoonsgegevens, (c) jouw persoonsgegevens in een
							gestructureerde, gangbare en machine-leesbare vorm
							te verkrijgen en (d) een klacht in te dienen bij de
							toezichthoudende overheid (in Belgi├½ de
							Gegevensbeschermingsautoriteit,
							https://www.gegevensbeschermingsautoriteit.be).
						</p>

						<p>
							Je kan deze rechten uitoefenen door te mailen naar
							marketing@yntro.be.
						</p>

						<p>
							We houden deze gegevens bij tot 3 jaar na het
							be├½indigen van jouw uitbatingsmandaat (bijvoorbeeld
							als je het vastgoed hebt doorverkocht) De
							verantwoordelijke is NV Bedecom met haar zetel te
							Gent, Kortrijksesteenweg 1043, ingeschreven in de
							KBO onder nummer BE0440 234 894. Je kan ons steeds
							bereiken op marketing@yntro.be.
						</p>

						<h3>SOLLICITEREN</h3>

						<p>
							Tof dat je overweegt om bij ons te solliciteren voor
							een functie. Wij bewaren uiteraard je cv en de
							bijhorende correspondentie, en noteren wat je ons
							vertelde tijdens eventuele gesprekken. Wij vragen je
							toestemming op het moment dat je via de websites
							jouw cv verstuurt, uiteraard kan je te allen tijde
							de toestemming intrekken, zonder dat dit afbreuk
							doet aan de rechtmatigheid van de verwerking op
							basis van de toestemming, voor de intrekking
							daarvan.
						</p>

						<p>
							Je hebt te allen tijde het recht om (a) toegang tot,
							verbetering of verwijdering van jouw
							persoonsgegevens te verzoeken, (b) de beperking van
							verwerking van jouw persoonsgegevens te verzoeken of
							zich te verzetten tegen de verwerking van je
							persoonsgegevens, (c) jouw persoonsgegevens in een
							gestructureerde, gangbare en machine-leesbare vorm
							te verkrijgen en (d) een klacht in te dienen bij de
							toezichthoudende overheid (in Belgi├½ de
							Gegevensbeschermingsautoriteit,
							https://www.gegevensbeschermingsautoriteit.be).
						</p>

						<p>
							Je kan deze rechten uitoefenen door te mailen naar
							marketing@yntro.be.
						</p>

						<p>
							Ook geven we jouw kandidatuur en de daarbij
							verkregen informatie, door onder Verbonden
							Ondernemingen, mocht blijken dat jouw profiel beter
							geschikt kan zijn voor hen. De verwerking van de
							gegevens gebeurt via onze CRM-systeem-provider. Wij
							kunnen jouw gegevens eveneens doorgeven aan derde
							partijen (inclusief overheden), indien wij hiertoe
							verplicht zijn op basis van wettelijke bepalingen of
							beslissingen van rechtbanken of indien dit
							noodzakelijk is in het kader van de uitvoering van
							de overeenkomst.
						</p>

						<p>
							We houden jouw gegevens bij gedurende 3 jaar in een
							wervingsreserve. De verantwoordelijke is NV Bedecom
							met haar zetel te Gent, Kortrijksesteenweg 1043,
							ingeschreven in de KBO onder nummer BE0440 234 894.
							Je kan ons steeds bereiken op marketing@yntro.be.
						</p>

						<h3>
							WERKEN BIJ YNTRO OF ├ë├ëN VAN HAAR VERBONDEN
							ONDERNEMINGEN
						</h3>

						<p>
							Tof jou als collega te kunnen verwelkomen. Om onze
							overeenkomst te kunnen uitvoeren, houden we
							persoonsgebonden informatie van jou bij. Uiteraard
							zijn er ook wettelijke verplichtingen en
							verplichtingen inzake sociaal zekerheidsrecht en
							arbeidsrecht waardoor wij persoonsgegevens zullen
							moeten verwerken. (bv invullen van een DIMONA
							aangifte). Dit gaat over identiteitsgegevens, maar
							ook alle informatie die we bekomen hebben tijdens
							het rekruteringsgesprek, testresultaten, inhoud van
							evaluatiegesprekken, rekeningnummers, burgerlijke
							staat, je loon, historiek omtrent verlofdagen, je
							verjaardag, het aantal kinderen... en laten we deze
							verwerken door Itproviders en payroll providers. Wij
							kunnen jou niet tewerkstellen indien je deze
							gegevens niet aan ons wenst te bezorgen. Wij sturen
							deze gegevens ook door naar Verbonden Ondernemingen
							en andere ontvangers als verzekeraars. Wij kunnen
							jouw gegevens eveneens doorgeven aan derde partijen
							(inclusief overheden), indien wij hiertoe verplicht
							zijn op basis van wettelijke bepalingen of
							beslissingen van rechtbanken of indien dit
							noodzakelijk is in het kader van de uitvoering van
							de overeenkomst.
						</p>

						<p>
							Wij houden de gegevens 5 jaar bij, nadat je onze
							firma verliet. Daarna geanonimiseerd voor
							statistische doeleinden.
						</p>

						<p>
							Je hebt te allen tijde het recht om (a) toegang tot,
							verbetering of verwijdering van jouw
							persoonsgegevens te verzoeken, (b) de beperking van
							verwerking van jouw persoonsgegevens te verzoeken of
							zich te verzetten tegen de verwerking van je
							persoonsgegevens, (c) jouw persoonsgegevens in een
							gestructureerde, gangbare en machine-leesbare vorm
							te verkrijgen en (d) een klacht in te dienen bij de
							toezichthoudende overheid (in Belgi├½ de
							Gegevensbeschermingsautoriteit,
							https://www.gegevensbeschermingsautoriteit.be).
						</p>

						<p>
							Je kan deze rechten uitoefenen door te mailen naar
							marketing@yntro.be.
						</p>

						<p>
							De verantwoordelijke is NV Bedecom met haar zetel te
							Gent, Kortrijksesteenweg 1043, ingeschreven in de
							KBO onder nummer BE0440 234 894. Je kan ons steeds
							bereiken op marketing@yntro.be.
						</p>
					</Label>
				</ModalBody>
			</Modal>
			<Container fluid>
				<Row className='bg-white justify-content-center'>
					<small className='mx-auto text-footer-color my-5 font-family-light'>
						©2022 CopyRight |{' '}
						<span
							className='pointer-cursor'
							onClick={() => setClickable(true)}
						>
							Cookiebeleid
						</span>{' '}
						|{' '}
						<span
							className='pointer-cursor'
							onClick={() => setPrivacy(true)}
						>
							Privacy beleid
						</span>
					</small>
				</Row>
			</Container>
		</>
	);
}

export default Footer;
