import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Row,
  Container,
  Form,
  Label,
  Input,
  Modal,
  ModalBody,
  Button,
  Spinner,
  FormFeedback,
} from "reactstrap";
import houseView from "../../assets/img/houseView.png";
import Laag88 from "../../assets/img/Laag88.png";
import Laag89 from "../../assets/img/Laag89.png";
import Laag90 from "../../assets/img/Laag90.png";
import Laag91 from "../../assets/img/Laag91.png";
import Ylogo from "../../assets/img/Y-logo2.png";
import RightArrow from "../../assets/img/rightArrow.png";
import ThankYouForm from "./Form/ThankyouForm";
import {
  createNewLead,
  setStreetError,
} from "../../store/actions/streetAction";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

function Header() {
  const [step, setStep] = useState(false);
  const [Okay, setOkay] = useState(true);
  const [bootCheck, setBootCheck] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [emailModal, setEmailModal] = useState(false);
  const [siteSubId, setSiteSubId] = useState("");
  const [publisherId, setPublisherId] = useState("");

  // Email Modal Toggle
  const emailToggle = () => {
    setEmailModal(!email);
  };

  const checkNameRegex = (val) => {
    let validName = /^[a-zA-Z\s.,'-]{1,30}$/;
    // let validName = /^[a-zA-Z]+ [a-zA-Z]+$/;
    if (val != "") {
      if (validName.test(val)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  const checkLastName = (val) => {
    let validName = /^[a-zA-Z\s.,'-]{1,30}$/;
    if (val != "") {
      if (validName.test(val)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const checkEmailRegex = (val) => {
    let validName = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z]{2,}$/;
    if (val != "") {
      if (validName.test(val)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const checkPhoneRegex = (val) => {
    let validName = /^(0|\+?32|00\s?32)([-\s]?\d[-\s]?){7,8}\d$/;
    if (val != "") {
      if (validName.test(val)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const dispatch = useDispatch();
  let params = useParams();
  const history = useHistory();

  useEffect(() => {
    const query = history.location.search;
    if (query) {
      const site_subid = new URLSearchParams(query).get("oa_id");
      const publisher_id = new URLSearchParams(query).get("siteid");
      // console.log(site_subid, 'site_subid');
      setSiteSubId(site_subid);
      // console.log(publisher_id, ' publisher_id');
      setPublisherId(publisher_id);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      checkNameRegex(firstName) === false &&
      checkLastName(lastName) === false &&
      checkEmailRegex(email) === false &&
      checkPhoneRegex(phoneNumber) === false
    ) {
      let phoneNumberWithoutPunctuation = phoneNumber
        .replace(/[\s-]/g, "")
        .replace(/^00(\d+)$/, "$1");

      if (!phoneNumberWithoutPunctuation.startsWith("0")) {
        // Prepend a plus sign
        if (!phoneNumberWithoutPunctuation.startsWith("+")) {
          phoneNumberWithoutPunctuation = "+" + phoneNumberWithoutPunctuation;
        }
      }
      console.log(
        "phoneNumberWithoutPunctuation: ",
        phoneNumberWithoutPunctuation
      );

      let update = {
        firstname: firstName,
        lastname: lastName,
        email: email,
        phone_number: phoneNumberWithoutPunctuation,
        language: "nl_BE",
        answers: ["3631"],
        site_custom_url: "http://yntro.aanvraaginformatie.be/",
        site_custom_name: "yntro_ibisdehaan",
        site_subid: siteSubId,
        publisher_id: publisherId,
        bootCheck: bootCheck,
      };
      dispatch(createNewLead(update));
    }
  };

  const { create_response, error_response } = useSelector(
    (state) => state.street
  );
  // console.log(create_response, 'create_response');
  const { loading } = useSelector((state) => state.street);

  return (
    <>
      {/* <Modal isOpen={emailModal} centered toggle={emailToggle}>
				<ModalBody>
					<div className='text-center'>
						<br />
						<span className='mt-5 font-family-light'>
							Je e-mail adres lijkt een spelfout te bevatten.
							Gelieve deze te controleren voordat u verder gaat.
						</span>
						<br />
						<Button
							color='success'
							className='font-family-bold float-right text-white'
							onClick={() => {
								setOkay(false);
								emailToggle();
							}}
						>
							OKÉ
						</Button>{' '}
					</div>
				</ModalBody>
			</Modal> */}
      <Container className="mt-3">
        <h3 className="mb-0 pb-0  font-weight-bold">
          Investeren in Ibis De Haan{" "}
        </h3>
        <Row className="py-3">
          <Col md="6">
            <div>
              <img src={houseView} alt="houseView" className="w-100" />
            </div>
            {/* {step ===  ? ( */}
            {/* <> */}
            {/* <Form
									className='my-2 p-3 bg-form-color form-height'
									onSubmit={handleSubmit}
								>
									<Container>
										<p className='text-white fs--20 justify-content-center font-family-bold'>
											Ja, ik wil vrijblijvend meer
											informatie ontvangen
										</p>
										<hr />
										<Row className=''>
											<Col xs='6' className='pr-1'>
												<label className='my-1 ml-3 text-white'>
													Voornaam
												</label>
												<Input
													required
													className='text-white bg-input-color form-Input pr-0 m-0  border-0 rounded-0'
													placeholder='bv. Peter'
													invalid={checkNameRegex(
														firstName
													)}
													// invalid={checkFirstName}
													onChange={e =>
														setFirstName(
															e.target.value
														)
													}
												/>
											</Col>
											<Col xs='6' className='pl-1'>
												<span>
													<label className='my-1 ml-3 text-white'>
														Achternaam
													</label>
													<Input
														required
														className='text-white bg-input-color form-Input pr-0 m-0  border-0 rounded-0'
														placeholder='bv. Jansen'
														invalid={checkLastName(
															lastName
														)}
														// invalid={
														// 	checkLastNameRegex
														// }
														onChange={e => {
															setLastName(
																e.target.value
															);
															// checkNameRegex(
															// 	firstName
															// );
														}}
													/>
												</span>
											</Col>
										</Row>
										<Row className='mt-3'>
											<Col xs='6' className='pr-1'>
												<label className='my-1 ml-3 text-white'>
													E-mail adres
												</label>
												<Input
													required
													className='text-white bg-input-color form-Input pr-0 mr-0  border-0 rounded-0'
													placeholder='bv. peter@jansen.be'
													invalid={checkEmailRegex(
														email
													)}
													onChange={e => {
														setEmail(
															e.target.value
														);
													}}
												/>
											</Col>
											<Col xs='6' className='pl-1'>
												<label className='my-1 ml-3 text-white'>
													Telefoonnummer
												</label>
												<Input
													required
													className='text-white bg-input-color form-Input pr-0 m-0 border-0 rounded-0'
													placeholder='bv. 0478504030'
													invalid={checkPhoneRegex(
														phoneNumber
													)}
													onChange={e => {
														setPhoneNumber(
															e.target.value
														);
													}}
												/>
											</Col>
										</Row>

										<Row className='my-1'>
											<Col className='d-flex'>
												<button
													type='submit'
													className='text-form-color border-0 btn-yellow py-2 fs--18 px-5  mt-4  text-left btn-hover'
												>
													{loading ? (
														<Spinner
															size='md'
															color='dark'
														/>
													) : (
														<>
															Ik ben
															geïnteresseerd
															<span>
																<img
																	src={
																		RightArrow
																	}
																	alt='symbol'
																/>
															</span>
														</>
													)}
												</button>
											</Col>
											<small className='text-white my-3 text-justify mb-3 mx-3'>
												Door hieronder op 'ik ben
												geïnteresseerd' te klikken, geef
												je toestemming aan Yntro om de
												hierboven ingediende
												persoonlijke informatie op te
												slaan en te verwerken om je van
												de gevraagde inhoud te voorzien.
											</small>
										</Row>
									</Container>
								</Form> */}
            {/* </> */}
            {/* ) : */}
            {create_response ? (
              <>
                {/* <Register
									firstName={firstName}
									lastName={lastName}
									phoneNumber={phoneNumber}
									email={email}
								/> */}
                <ThankYouForm />
              </>
            ) : (
              <Form
                className="my-2 p-3 bg-form-color form-height"
                onSubmit={handleSubmit}
              >
                <Container>
                  <p className="text-white fs--20 justify-content-center font-family-bold">
                    Ja, ik wil vrijblijvend meer informatie ontvangen
                  </p>
                  <hr />
                  <Row className="">
                    <Col xs="6" className="pr-1">
                      <label className="my-1 ml-3 text-white">Voornaam</label>
                      <Input
                        required
                        className="text-white bg-input-color form-Input pr-0 m-0  border-0 rounded-0"
                        placeholder="bv. Peter"
                        invalid={
                          checkNameRegex(firstName) || error_response?.firstname
                        }
                        // invalid={checkFirstName}
                        onChange={(e) => {
                          setFirstName(e.target.value);
                          error_response?.firstname &&
                            dispatch(setStreetError());
                        }}
                      />
                      <FormFeedback>Voornaam niet correct</FormFeedback>
                    </Col>
                    <Col xs="6" className="pl-1">
                      <span>
                        <label className="my-1 ml-3 text-white">
                          Achternaam
                        </label>
                        <Input
                          required
                          className="text-white bg-input-color form-Input pr-0 m-0  border-0 rounded-0"
                          placeholder="bv. Jansen"
                          invalid={
                            checkLastName(lastName) || error_response?.lastname
                          }
                          // invalid={
                          // 	checkLastNameRegex
                          // }
                          onChange={(e) => {
                            setLastName(e.target.value);
                            // checkNameRegex(
                            // 	firstName
                            // );
                            error_response?.lastname &&
                              dispatch(setStreetError());
                          }}
                        />
                        <FormFeedback>Achternaam niet correct</FormFeedback>
                      </span>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs="6" className="pr-1">
                      <label className="my-1 ml-3 text-white">
                        E-mail adres
                      </label>
                      <Input
                        required
                        className="text-white bg-input-color form-Input pr-0 mr-0  border-0 rounded-0"
                        placeholder="bv. peter@jansen.be"
                        invalid={
                          checkEmailRegex(email) || error_response?.email
                        }
                        onChange={(e) => {
                          setEmail(e.target.value);
                          error_response?.email && dispatch(setStreetError());
                        }}
                      />
                      <FormFeedback>E-mailadres niet correct</FormFeedback>
                    </Col>
                    <Col xs="6" className="pl-1">
                      <label className="my-1 ml-3 text-white">
                        Telefoonnummer
                      </label>
                      <Input
                        required
                        className="text-white bg-input-color form-Input pr-0 m-0 border-0 rounded-0"
                        placeholder="bv. 0478504030"
                        invalid={
                          checkPhoneRegex(phoneNumber) ||
                          error_response?.phone_number
                        }
                        onChange={(e) => {
                          setPhoneNumber(e.target.value);
                          error_response?.phone_number &&
                            dispatch(setStreetError());
                        }}
                      />
                      <FormFeedback>Telefoonnummer niet correct</FormFeedback>
                    </Col>
                  </Row>

                  <Row className="my-1">
                    <Input
                      type="checkbox"
                      value={bootCheck}
                      onChange={() => setBootCheck(!bootCheck)}
                      style={{ opacity: "0.001" }}
                    />
                    <Col className="d-flex">
                      <button
                        type="submit"
                        className="text-form-color border-0 btn-yellow py-2 fs--18 px-md-5  mt-4  text-left btn-hover"
                      >
                        {loading ? (
                          <Spinner size="md" color="dark" />
                        ) : (
                          <>
                            Ik ben geïnteresseerd
                            <span>
                              <img
                                src={RightArrow}
                                alt="symbol"
                                className="img-fluid"
                              />
                            </span>
                          </>
                        )}
                      </button>
                    </Col>
                    <small className="text-white my-3 text-justify mb-3 mx-3">
                      Door hieronder op 'ik ben geïnteresseerd' te klikken, geef
                      je toestemming aan Yntro om de hierboven ingediende
                      persoonlijke informatie op te slaan en te verwerken om je
                      van de gevraagde inhoud te voorzien.
                    </small>
                  </Row>
                </Container>
              </Form>
            )}
          </Col>
          <Col md="6">
            <Row className="justify-content-center">
              <Col xs="5" md="6">
                <img src={Laag88} alt="laag88" className="w-100 mb-2 mr-2" />
              </Col>
              <Col xs="5" md="6">
                <img src={Laag89} alt="laag89" className="w-100 mb-2 " />
              </Col>
              <Col xs="5" md="6">
                <img src={Laag91} alt="laag91" className="w-100 mt-2 mt-md-0" />
              </Col>
              <Col xs="5" md="6">
                <img
                  src={Laag90}
                  alt="laag90"
                  className="w-100 mt-2 mt-md-0"
                  // w-100 mr-2
                />
              </Col>
            </Row>

            <div className="my-2 p-3">
              <div>
                <h4 className="font-family-bold">
                  Voordelen van investeren met Yntro:
                </h4>
                <Row>
                  <div className="sidebar-logo text-center">
                    <img src={Ylogo} alt="ylogo" className="img-fluid w-50" />
                  </div>

                  <div className="sideBar-text">
                    <p className="font-family-light">
                      <span className="font-weight-bold">
                        Volledige ontzorging{" "}
                      </span>{" "}
                      door professionele verhuurpartner
                    </p>
                  </div>
                </Row>
                <Row className="mt-2">
                  <div className="sidebar-logo text-center">
                    <img alt="ylogo" src={Ylogo} className="img-fluid w-50" />
                  </div>

                  <div className="sideBar-text">
                    <p className="font-family-light">
                      Een{" "}
                      <span className="font-weight-bold">
                        vrijblijvend kennismakingsgesprek{" "}
                      </span>{" "}
                      om jouw zoektocht in kaart te brengen en jou hierbij te
                      ondersteunen.
                    </p>
                  </div>
                </Row>
                <Row className="mt-2">
                  <div className="sidebar-logo text-center">
                    <img alt="ylogo" src={Ylogo} className="img-fluid w-50" />
                  </div>

                  <div className="sideBar-text">
                    <p className="font-family-light">
                      <span className="font-weight-bold">
                        Yntro begeleid je door het gehele project{" "}
                      </span>
                      : van kennismaking tot notariële akte.
                    </p>
                  </div>
                </Row>
                <Row className="mt-2">
                  {/* <Col xs='2'> */}
                  <div className="sidebar-logo text-center">
                    <img alt="ylogo" src={Ylogo} className="img-fluid w-50 " />
                  </div>
                  {/* </Col> */}
                  {/* <Col xs='10'> */}
                  <div className="sideBar-text ">
                    <p className="font-family-light">
                      <span className="font-weight-bold fw--800">
                        Voordelig zelf van genieten:
                      </span>
                      <ul className="p-3">
                        <li className="font-family-light fw--400">
                          Verwarmd buitenzwembad
                        </li>
                        <li className="font-family-light fw--400">
                          Vlakbij het strand en de duinen
                        </li>
                        <li className="font-family-light">Gezellige bar</li>
                      </ul>
                    </p>
                  </div>
                  {/* </Col> */}
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Header;
