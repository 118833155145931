import React from "react";
import { Col, Container, Row } from "reactstrap";
import RightArrow from "../assets/img/rightArrow.png";

function AnnPakSection() {
  return (
    <>
      <Container className="bg-form-color" fluid>
        <Container>
          <Row>
            <Col md="5" className="m-auto p-3">
              <h4 className="text-white py-4  fw--400">ONZE AANPAK</h4>
            </Col>
            <Col md="5" className="m-auto"></Col>
          </Row>
          <Row className="p-3">
            <Col sm="6" md="5" className="mx-auto">
              <div className="d-flex ">
                <div className="bg-yellow text-form-color p-2 text-center font-family-bold fs--25 label">
                  1
                </div>
                <h4 className="align-self-center text-yellow ml-3 mb-0">
                  Kennismaking{" "}
                </h4>
              </div>
              <p className="text-p-color my-2 font-weight-light fs--18">
                Wij komen naar je toe of jij komt naar ons, om in de eerste
                plaats te luisteren naar jouw wensen en verwachtingen. Hebben we
                wat je zoekt? Zitten we op dezelfde golflengte? Dan leggen we
                haarfijn uit wat onze firma voor je kan doen en maken we er werk
                van.
              </p>
            </Col>
            <Col sm="6" md="5" className="mx-auto">
              <div className="d-flex ">
                <div className="bg-yellow text-form-color p-2 text-center font-family-bold fs--25 label">
                  2
                </div>
                <h4 className="align-self-center text-yellow ml-3 mb-0">
                  Bezoek ter plaatse{" "}
                </h4>
              </div>
              <p className="text-p-color my-2 font-weight-light fs--18">
                We nodigen je uit om het volledige vastgoedproject te komen
                bekijken en meer bepaald de ligging en de units waarin je
                geïnteresseerd bent. We beantwoorden al je vragen, bespreken de
                prijs en overlopen de mogelijkheden van de samenwerking.
              </p>
            </Col>
          </Row>
          <Row className="p-3">
            <Col sm="6" md="5" className="mx-auto">
              <div className="d-flex ">
                <div className="bg-yellow text-form-color p-2 text-center font-family-bold fs--25 label">
                  3
                </div>
                <h4 className="align-self-center text-yellow ml-3 mb-0">
                  Beslissing
                </h4>
              </div>
              <p className="text-p-color my-2 font-weight-light fs--18">
                {" "}
                Proficiat, je hebt beslist om de trotse eigenaar van vastgoed te
                worden. Tijd dus om het één en ander op papier te zetten en de
                compromis op te stellen, zodat de notaris de akte kan
                voorbereiden.
              </p>
            </Col>
            <Col sm="6" md="5" className="mx-auto">
              <div className="d-flex ">
                <div className="bg-yellow text-form-color p-2 text-center font-family-bold fs--25 label">
                  4
                </div>
                <h4 className="align-self-center text-yellow ml-3 mb-0">
                  Genieten
                </h4>
              </div>
              <p className="text-p-color my-2 font-weight-light fs--18">
                Het moment is aangebroken om te beginnen genieten van je
                aankoop. Samen met de uitbater overloop je stap voor stap alle
                praktische zaken. Bovendien kun je blijven rekenen op de
                ondersteuning van Yntro, want je bent nu lid van onze community.{" "}
              </p>
            </Col>
          </Row>
          <div className="d-flex">
            <button
              type="button"
              className="text-form-color btn-yellow mx-auto my-5 py-2 fs--16 px-md-4 text-left btn-hover border-0"
              onClick={() => window.scrollTo(0, 180)}
            >
              Ik wil meer informatie
              <span>
                <img src={RightArrow} className="img-fluid arrowInformatie" />
              </span>
            </button>
          </div>
        </Container>
      </Container>
    </>
  );
}

export default AnnPakSection;
