import React from 'react';
import AnnPakSection from '../components1/AnnPakSection';
import Footer from '../components1/Footer';
import Header from '../components1/Header/Header';
import ImagesSection from '../components1/ImagesSection';
import KopieSection from '../components1/KopieSection';

function Uitgelicht() {
	return (
		<>
			<Header />
			<ImagesSection />
			<KopieSection />
			<AnnPakSection />
			<Footer />
		</>
	);
}

export default Uitgelicht;
